import React from "react"
import Helmet from "react-helmet"
import UpdatedPrivacy from "src/components/updatedPrivacy"
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"

export default function UpdatedPrivacyPolicy() {
  return(

    <HomepageLayout>
        <Helmet data={JSONData}><title>Privacy Policy - fabtab.org</title></Helmet>
    <UpdatedPrivacy>    <Helmet>Test</Helmet></UpdatedPrivacy>
    </HomepageLayout>
  )
}
